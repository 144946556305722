




















































































































































































































































import mixins from 'vue-typed-mixins'
import { mapState } from 'vuex'
import { FORGOT_PASSWORD, VERIFY_PENDING_USER, VERIFY_USER } from '../graphql'
import { ACTIONS, MUTATIONS } from '../store/consts'
import { appCreated } from '@simpl/core/init-app'
import { TranslateResult } from 'vue-i18n'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import SelfRegisterDialog from './SelfRegisterDialog.vue'
import PasswordHints from './PasswordHints.vue'
import PasswordValidator from '../mixins/PasswordValidator'
import ValidationRules from '@simpl/core/mixins/utils/ValidationRules'
import PrivacyConsentCheckbox from '@simpl/core/components/PrivacyConsentCheckbox.vue'

export default mixins(PasswordValidator, ValidationRules).extend({
  name: 'Login',

  components: {
    SelfRegisterDialog,
    PasswordHints,
    PrivacyConsentCheckbox
  },

  props: {
    userId: String,
    token: String,
    email: String,
    redirectUrl: String,
    isDialog: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      valid: true,
      // login
      showPassword: false,
      username: '',
      password: '',
      code: '',
      rememberMe: null,

      loading: false,
      loggingIn: false,
      loginFailed: false,

      // (re)set password
      forgotPassword: false,
      resetPassword: false,
      showAutoLoginHint: false,

      showRegisterDialog: false,

      termsAccepted: false,

      badge: process.env.VUE_APP_ENV_BADGE || ''
    }
  },

  computed: {
    ...mapState({
      loggedIn: (state: any) => !!state.auth.token && (!state.auth.user.tfa_enabled || !!state.auth.twoFactorToken)
    }),
    isAutoLogin (): boolean {
      return this.$route.name === 'auto-login' || this.showAutoLoginHint
    },
    isSetPassword (): boolean {
      return this.$route.name === 'password-reset' || this.$route.name === 'accept-invitation'
    },
    isAcceptInvitation (): boolean {
      return this.$route.name === 'accept-invitation'
    },
    isVerifyUser (): boolean {
      return this.$route.name === 'verify-user'
    },
    isVerifyPendingUser (): boolean {
      return this.$route.name === 'verify-pending-user'
    },
    isTwoFactorChallenge (): boolean {
      return (this.$route.name === 'login-challenge') ||
        (this.$store.state.auth.token && (this.$route.name === 'login' || !this.loggedIn))
    },
    showPasswordRequirements (): boolean {
      return !this.passwordIsValid
    },
    isLoading (): boolean {
      return this.loggingIn || this.loading
    },
    buttonText (): TranslateResult {
      return this.forgotPassword ? this.$t('core.action.send') : this.$t('simplAuth.auth.login')
    },
    domainName (): string {
      return this.$store.state.auth.domain ? getTextForTemporaryUserLanguage(this.$store.state.auth.domain) : ''
    },
    selfRegistrationEnabled (): boolean {
      return this.$store.state.auth.domain?.properties?.self_registration?.enabled
    }
  },

  async created () {
    if (this.isVerifyUser) {
      await this.verifyUser()
    }
    if (this.isVerifyPendingUser) {
      await this.verifyPendingUser()
    }

    if (this.isAutoLogin) {
      await this.autoLogin()
    }

    if (this.loggedIn && (this.isAcceptInvitation || this.isSetPassword || this.isVerifyUser || this.isVerifyPendingUser)) {
      await this.$confirm({
        message: this.$t('core.message.loggedInButShouldNot'),
        buttons: [{
          text: this.$t('core.global.ok'),
          type: 'outlined',
          answer: true
        }]
      })
      this.$router.push('/')
    }
  },

  methods: {
    submit () {
      if (this.isSetPassword) {
        this.setPassword()
      } else if (this.forgotPassword) {
        this.sendEmail()
      } else {
        this.isTwoFactorChallenge ? this.loginTwoFactor() : this.login()
      }
    },
    async onLoginSuccessful (targetUrl?: string) {
      if (!this.isDialog || this.redirectUrl) {
        await appCreated()

        setTimeout(() => {
          if (this.$router.currentRoute.path !== '/') {
            this.$router.push(this.redirectUrl || targetUrl || '/')
          }
        }, 200)
      } else {
        await appCreated(true)
      }
    },
    onLoginFailed (error: any) {
      (error.graphQLErrors || []).forEach((err: Error) => {
        let message = err.message
        if (message.substr(0, 33) === 'simpl-access-control.redirect-to:') {
          if (this.$router.currentRoute.name !== 'base-management.event.view') {
            message = 'simpl-access-control.redirect-to'
            this.showAutoLoginHint = true
            this.loading = true
          } else {
            message = 'simpl-access-control.register-to'
          }
        }
        this.$notification.publish('bottom', {
          message: this.$t(message),
          borderColor: 'error'
        })
      })
    },
    async login () {
      this.loggingIn = true

      const {
        username,
        password
      } = this

      try {
        this.$store.commit(`auth/${MUTATIONS.REMEMBER}`, { rememberMe: this.rememberMe })

        const targetUrl = (
          this.$store.state.auth.lastUrl !== this.$router.currentRoute.path &&
          this.$store.state.auth.lastUrl
        ) || '/'

        await this.$store.dispatch(`auth/${ACTIONS.LOGIN}`, {
          username,
          password
        })

        if (this.$store.state.auth.user.tfa_enabled) {
          if (!this.isDialog) await this.$router.push('/login-challenge')
        } else {
          await this.onLoginSuccessful(targetUrl)
        }
      } catch (e) {
        this.onLoginFailed(e)
        this.loginFailed = true
      }

      this.loggingIn = false
    },
    async loginTwoFactor () {
      this.loggingIn = true
      try {
        const targetUrl = (
          this.$store.state.auth.lastUrl !== this.$router.currentRoute.path &&
          this.$store.state.auth.lastUrl
        ) || '/'

        await this.$store.dispatch(`auth/${ACTIONS.PERFORM_TWO_FACTOR_CHALLENGE}`, {
          code: this.code
        })

        await this.onLoginSuccessful(targetUrl)
      } catch (e) {
        this.onLoginFailed(e)
        this.loginFailed = true
      }
      this.loggingIn = false
    },
    async setPassword () {
      if (this.isAcceptInvitation && !this.termsAccepted) return
      this.loggingIn = true

      const action = this.isAcceptInvitation ? ACTIONS.ACCEPT_INVITATION : ACTIONS.RESET_PASSWORD

      try {
        await this.$store.dispatch(`auth/${action}`, {
          token: this.token,
          email: this.email,
          password: this.newPassword,
          password_confirmation: this.confirmPassword
        })
        if (this.$store.state.auth.user.tfa_enabled) {
          await this.$router.push('/login-challenge')
        } else {
          await this.onLoginSuccessful()
        }
      } catch (e) {
        this.onLoginFailed(e)
      }

      this.loggingIn = false
    },
    async sendEmail () {
      this.loading = true

      await this.$apollo.mutate({
        mutation: FORGOT_PASSWORD,
        variables: {
          data: { email: this.username }
        }
      })

      this.loading = false

      this.$notification.publish('bottom', {
        message: this.$t('core.message.emailSent'),
        type: 'success',
        color: 'success'
      })
    },
    async verifyUser () {
      try {
        await this.$apollo.mutate({
          mutation: VERIFY_USER,

          variables: {
            id: this.userId
          }
        })
        this.$notification.publish('bottom', {
          message: this.$t('core.message.userVerified'),
          type: 'success',
          color: 'success'
        })
      } catch (e) {
        (e.graphQLErrors || []).forEach((err: Error) => {
          this.$notification.publish('bottom', {
            message: this.$t(err.message),
            borderColor: 'error'
          })
        })
      }
      await this.$router.push('/login')
    },
    async verifyPendingUser () {
      try {
        await this.$apollo.mutate({
          mutation: VERIFY_PENDING_USER,

          variables: {
            id: this.userId
          }
        })
        this.$notification.publish('bottom', {
          message: this.$t('core.message.pendingUserVerified'),
          type: 'success',
          color: 'success'
        })
      } catch (e) {
        (e.graphQLErrors || []).forEach((err: Error) => {
          this.$notification.publish('bottom', {
            message: this.$t(err.message),
            borderColor: 'error'
          })
        })
      }
      await this.$router.push('/login')
    },
    async autoLogin () {
      this.loggingIn = true
      try {
        await this.$store.dispatch(`auth/${ACTIONS.AUTO_LOGIN}`, this.$route.params.token)

        if (this.$store.state.auth.user.tfa_enabled) {
          if (!this.isDialog) await this.$router.push('/login-challenge')
        } else {
          await this.onLoginSuccessful()
        }
      } catch (e) {
        this.onLoginFailed(e)
        this.loginFailed = true
        await this.$router.push('/login')
      }

      this.loggingIn = false
    }
  }
}
)
