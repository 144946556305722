import { MutationTree } from 'vuex'
import StateAuth from './types'
import { MUTATIONS } from './consts'
import i18n from '@simpl/core/plugins/i18n'

const mutations = {
  [MUTATIONS.SET_AUTH_PAYLOAD]: (state, payload) => {
    state.user = payload.user
    state.token = payload.access_token
    state.refreshToken = payload.refresh_token
    state.expiresAt = Math.floor((+new Date()) / 1000) + payload.expires_in
    state.lastTokenUpdate = (+new Date())
    state.lastUrl = undefined
    state.impersonator = null
    state.anonUsername = null

    i18n.locale = state.user.languagecode!
  },
  [MUTATIONS.IMPERSONATE]: (state, payload) => {
    state.impersonator = { ...state }
    state.user = payload.user
    state.token = payload.access_token
    state.refreshToken = payload.refresh_token
    state.expiresAt = Math.floor((+new Date()) / 1000) + payload.expires_in
    state.lastTokenUpdate = (+new Date())
    state.lastUrl = undefined

    i18n.locale = state.user.languagecode!
  },
  [MUTATIONS.REVOKE_IMPERSONATION]: (state, payload) => {
    state.user = payload.user
    state.token = payload.token
    state.refreshToken = payload.refreshToken
    state.expiresAt = payload.expiresAt
    state.lastTokenUpdate = payload.lastTokenUpdate
    state.lastUrl = payload.lastUrl
    state.twoFactorToken = payload.twoFactorToken
    state.impersonator = null

    i18n.locale = state.user.languagecode!
  },
  [MUTATIONS.REMEMBER]: (state, { rememberMe }) => {
    state.rememberMe = rememberMe
  },
  [MUTATIONS.SET_TEMPORARY_LANGUAGE]: (state, { temporaryLanguage }) => {
    state.temporaryLanguage = temporaryLanguage
  },
  [MUTATIONS.SET_USER_LANGUAGE]: (state, language) => {
    state.user.languagecode = language
  },
  [MUTATIONS.UPDATE_ME]: (state, payload) => {
    state.user = { ...payload }
  },
  [MUTATIONS.LOGOUT]: (state) => {
    state.user = {} as any
    state.token = null
    state.refreshToken = null
    state.expiresAt = undefined
    state.impersonator = null
    state.twoFactorToken = null
    state.lastDemoPopup = null
    state.anonUsername = ''
  },
  [MUTATIONS.SET_LAST_URL]: (state, payload) => {
    state.lastUrl = payload
  },
  [MUTATIONS.SET_TWO_FACTOR_TOKEN]: (state, payload) => {
    state.twoFactorToken = payload
  },
  [MUTATIONS.SET_DOMAIN]: (state, payload) => {
    if (!payload) {
      if (process.env.VUE_APP_API_URL!.endsWith('.local')) {
        console.warn('DOMAIN WAS NOT FOUND - redirecting to simpl.training was blocked due to local env')
      } else {
        window.location.href = 'https://www.simpl.training'
      }
    }
    state.domain = payload
  },
  [MUTATIONS.SET_LAST_DEMO_POPUP]: (state, payload) => {
    state.lastDemoPopup = payload
  },
  [MUTATIONS.SET_SHOW_DEMO_POPUP]: (state, payload) => {
    state.showDemoPopup = payload
  },
  [MUTATIONS.SET_SHOW_PLANS_POPUP]: (state, payload) => {
    state.showPlansPopup = payload
  },
  [MUTATIONS.SET_LAST_OPENED_MODULE]: (state, payload) => {
    state.lastOpenedModule = payload
  },
  [MUTATIONS.SET_ANON_USERNAME]: (state, username) => {
    state.anonUsername = username
  }
} as MutationTree<StateAuth>

export default mutations
